<!-- 作品列表 -->
<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="deleteTree"
        v-if="showDeleteBtn"
        icon="delete"
        v-has="'production:list:button:delete'"
      >删除</a-button>
      <!-- <a-button class="mr-3" type="primary" v-has="'production:button:import'" icon="download" @click="exportExcel">导入</a-button> -->
      <!-- <a-button class="mr-3" type="primary" v-has="'production:button:export'" icon="download" @click="exportExcel">导出</a-button> -->
      <a-button
        type="primary"
        class="mr-3"
        @click="recycleBinVisible = true"
        icon="hdd"
        v-has="'production:list:button:recyclebin'"
      >回收站</a-button>
      <a-dropdown :trigger="['click']">
        <a-button type="primary" icon="plus" v-has="'production:list:button:add'">新建</a-button>
        <template #overlay>
          <a-menu>
            <!-- <a-menu-item class="open-new-btn" @click="openNewCompany">公司</a-menu-item> -->
            <!-- <a-menu-item class="open-new-btn" @click="openNewDepartment">部门</a-menu-item> -->
            <a-menu-item class="open-new-btn" @click="openNewPlatform">平台</a-menu-item>
            <a-menu-item class="open-new-btn" @click="openNewProduction">作品</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div class="table-page-search-wrapper" slot="search">
      <a-form
        layout="inline"
        @keyup.enter.native="searchQueryForm"
        v-has="'production:list:button:search'"
      >
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input
              placeholder="请输入作品名称或序号"
              v-model="queryParam.productionName"
              allowClear
            ></a-input>
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQueryForm">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <product-table-tree
      ref="product_table_tree"
      @showDeleteBtnFun="showDeleteBtnFun"
      :height="tableHeight"
      :expandRowKeys="expandRowKeys"
      @getExpandRowKeys="getExpandRowKeys"
      :productName="queryParam.productionName"
    />

    <production-modal
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    ></production-modal>

    <!-- 作品回收站 -->
    <production-recycle-bin-modal :visible.sync="recycleBinVisible" @ok="modalFormOk" />
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import ProductionModal from './modules/production-modal'
import { getAction } from '@/api/manage'
import ProductionRecycleBinModal from '@/views/production/modules/ProductionRecycleBinModal'
import ProductTableTree from '@/components/product-table-tree'

export default {
  name: 'product-management',
  mixins: [JeecgListMixin],
  components: {
    ProductionModal,
    ProductTableTree,
    ProductionRecycleBinModal
  },
  data() {
    return {
      keepAlive: true,
      recycleBinVisible: false,
      positionList: [],
      platforms: [],
      adminUsers: [],
      platformIds: [],
      adminUserIds: [],
      expandRowKeys: [],
      showDeleteBtn: false
    }
  },
  methods: {
    exportExcel() {
      this.$refs.product_table_tree.exportExcel()
    },
    getExpandRowKeys(key) {
      this.expandRowKeys.push(key)
      console.log(this.expandRowKeys, 'expandRowKeys')
    },
    showDeleteBtnFun(state) {
      this.showDeleteBtn = state
    },
    deleteTree() {
      this.$refs.product_table_tree.onRemoveRows()
    },
    getAllPlatform() {
      getAction('/platform/list?pageSize=1000').then(res => {
        if (res.success) {
          this.platforms = res.data.records
        }
      })
    },
    getAllPosition() {
      getAction('/sys/position/list').then(res => {
        if (res.success) {
          this.positionList = res.data.records
          this.positionList.forEach(one => {
            if (one.name == '统筹') {
              this.getAllAdminUsers(one.id)
            }
          })
        }
      })
    },
    getAllAdminUsers(postId) {
      return getAction('/sys/user/query_by_position', {
        positionId: postId
      }).then(({ data }) => {
        this.adminUsers = data
      })
    },
    searchQueryForm() {
      clearTimeout(this.serachInputTimer)
      this.serachInputTimer = setTimeout(() => {
        // 调用表格组件种的作品查询方法
        this.$refs.product_table_tree.getTreeData()
      }, 400)
    },
    emptyToSearch() {
      if (this.queryParam.productionName == '') {
        this.searchQueryForm()
      }
    },
    openNewCompany() {},
    openNewDepartment() {},
    openNewPlatform() {
      this.$router.push({ path: '/system/platform/detail/new', meta: { title: '新建平台' } })
    },
    openNewProduction() {
      this.$router.push({ path: '/production/manage/detail/new', meta: { title: '新建作品' } })
    },
    paddingZero(num, length) {
      if ((num + '').length >= length) {
        return num
      }
      return this.paddingZero('0' + num, length)
    }
  },
  created() {}
}
</script>

<style lang='less' scoped>
.red {
  color: red;
}
/deep/ .border-bottom {
  border: none;
}
.open-new-btn {
  display: flex;
  justify-content: center;
}
</style>