var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.showDeleteBtn
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:list:button:delete",
                      expression: "'production:list:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.deleteTree },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "production:list:button:recyclebin",
                  expression: "'production:list:button:recyclebin'",
                },
              ],
              staticClass: "mr-3",
              attrs: { type: "primary", icon: "hdd" },
              on: {
                click: function ($event) {
                  _vm.recycleBinVisible = true
                },
              },
            },
            [_vm._v("回收站")]
          ),
          _c(
            "a-dropdown",
            {
              attrs: { trigger: ["click"] },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "a-menu",
                        [
                          _c(
                            "a-menu-item",
                            {
                              staticClass: "open-new-btn",
                              on: { click: _vm.openNewPlatform },
                            },
                            [_vm._v("平台")]
                          ),
                          _c(
                            "a-menu-item",
                            {
                              staticClass: "open-new-btn",
                              on: { click: _vm.openNewProduction },
                            },
                            [_vm._v("作品")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:list:button:add",
                      expression: "'production:list:button:add'",
                    },
                  ],
                  attrs: { type: "primary", icon: "plus" },
                },
                [_vm._v("新建")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-form",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "production:list:button:search",
                  expression: "'production:list:button:search'",
                },
              ],
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQueryForm.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入作品名称或序号",
                          allowClear: "",
                        },
                        model: {
                          value: _vm.queryParam.productionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "productionName", $$v)
                          },
                          expression: "queryParam.productionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchQueryForm },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("product-table-tree", {
        ref: "product_table_tree",
        attrs: {
          height: _vm.tableHeight,
          expandRowKeys: _vm.expandRowKeys,
          productName: _vm.queryParam.productionName,
        },
        on: {
          showDeleteBtnFun: _vm.showDeleteBtnFun,
          getExpandRowKeys: _vm.getExpandRowKeys,
        },
      }),
      _c("production-modal", {
        attrs: {
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
        },
        on: { closed: _vm.drawerClosed },
      }),
      _c("production-recycle-bin-modal", {
        attrs: { visible: _vm.recycleBinVisible },
        on: {
          "update:visible": function ($event) {
            _vm.recycleBinVisible = $event
          },
          ok: _vm.modalFormOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }